import React from 'react'
import { connect } from 'react-redux'
import './how-will-you-pay-options.scss'

import { findById } from '../../database'

import { Link } from 'react-router-dom'

class HowWillYouPayOptions extends React.Component{
  render() {
    let location = findById(this.props.id)

    return (
      <div>
        <h1 className="page-title">Without help, state and local governments face two brutal options.</h1>

        <div className='container'>
          <div className='row'>
            <div className='col-md-6 animated fadeIn delay-1s'>
              <h2>Make drastic budget cuts, straining services, delaying expenditures, and impacting:</h2>
              <ul className='icons-listing'>
                <li className='icon-schools'>Schools</li>
                <li className='icon-hospitals_healthcare'>Hospitals and healthcare programs</li>
                <li className='icon-law_enforcement'>Emergency services, police, firefighters, and more</li>
              </ul>
            </div>
            <div className='col-md-6 animated fadeIn delay-2s'>
              <h2>Retreat and abandon property and communities, meaning:</h2>
              <ul className='icons-listing'>
                <li className='icon-flooded_homes'>Property values slide. Homes are vacated and residents are displaced. Governments are forced to compensate property owners.</li>
                <li className='icon-death_displacement'>Roads flood and critical infrastructure is compromised. Public safety is threatened.</li>
                <li className='icon-closed'>Parks, monuments and cultural sites are inundated. Local businesses are jeopardized.</li>
              </ul>
            </div>
          </div>
          <div className=' animated fadeIn delay-3s'>
            <Link to={ '/make-big-oil-pay/' + location.id } title="These are not viable options" className='button centered'>We can do better.</Link>
          </div>

        </div>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id
  }
}

export default connect(mapStateToProps)(HowWillYouPayOptions);