import React from 'react'
import rankings from '../../rankings.json'
import RankingsTable from '../../components/rankingsTable'

import './location-rankings.scss'

export default () => (
  <div>
    <h1 className="page-title">Location Rankings</h1>
    <div className='container'>
      <p>As sea-level rise will hit some coastal communities harder than others and price of construction varies from place to place, communities will face vastly different costs to protect against rising seas. See if yours made it to the top of the list.</p>

      <div className='jump-links'>
        <a href='#states-table' title='State Rankings' className="cost-link">State Rankings</a>
        <a href='#counties-table' title='County Rankings' className="cost-link">County Rankings</a>
        <a href='#cities-table' title='City Rankings' className="cost-link">City Rankings</a>
        <a href='#districts-table' title='District Rankings' className="cost-link">District Rankings</a>
      </div>

      <RankingsTable anchorName='states-table' index={ rankings.states } title='State Rankings' download={ process.env.PUBLIC_URL + '/files/State National Rankings - Climate Costs 2040.pdf' } />
      <RankingsTable anchorName='counties-table' index={ rankings.county } title='County Rankings' download={ process.env.PUBLIC_URL + '/files/County National Rankings - Climate Costs 2040.pdf' } />
      <RankingsTable anchorName='cities-table' index={ rankings.city } title='City Rankings' download={ process.env.PUBLIC_URL + '/files/City National Rankings - Climate Costs 2040.pdf' } />
      <RankingsTable anchorName='districts-table' index={ rankings.district } title='Congressional District Rankings' download={ process.env.PUBLIC_URL + '/files/Congressional District National Rankings - Climate Costs 2040.pdf' } />
    </div>
  </div>
)