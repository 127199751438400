import React from 'react';
import { Route } from 'react-router-dom'
import Home from '../home'
import About from '../about'
import LocationRankings from '../location-rankings'
import CostsDisplay from '../costs-display'
import DataSets from '../data-sets'
import DownloadTheReport from '../download-the-report'
import StateDetails from '../state-details'
import Header from '../../components/header'
import Footer from '../../components/footer'
import { connect } from 'react-redux'

import './app.scss'
import HowWillYouPayCosts from '../how-will-you-pay-costs'
import HowWillYouPayOptions from '../how-will-you-pay-options'
import MakeBigOilPay from '../make-big-oil-pay'

class App extends React.Component {
  render() {
    let sealevel = 1
    let pathname = this.props.router.location.pathname
    if (pathname.match(/^\/costs\//)) {
      sealevel = 2
    }
    else if (pathname.match(/^\/how-will-you-pay\//)) {
      sealevel = 3
    }
    else if (pathname.match(/^\/how-will-you-pay-options\//)) {
      sealevel = 4
    }
    else if (pathname.match(/^\/make-big-oil-pay\//)) {
      sealevel = 5
    }

    return (
      <div className={ 'sea-level-' + sealevel }>
        <Header />

        <main>
          <Route exact path="/" component={Home} />
          <Route exact path="/about-the-study" component={About} />
          <Route exact path="/rankings" component={LocationRankings} />
          <Route path="/how-will-you-pay/:id" component={HowWillYouPayCosts} />
          <Route path="/how-will-you-pay-options/:id" component={HowWillYouPayOptions} />
          <Route path="/make-big-oil-pay/:id" component={MakeBigOilPay} />
          <Route path="/costs/:id" component={CostsDisplay} />
          <Route exact path="/data-sets" component={DataSets} />
          <Route exact path="/download-the-report" component={DownloadTheReport} />
          <Route exact path="/state-details" component={StateDetails} />
        </main>

        <Footer />
        <div className='sea-level-wave'></div>
        <div className='sea-level'></div>
      </div>
    )
  }
}
// export default App

const mapStateToProps = (state, ownProps) => {
  return {
    router: state.router
  }
}

export default connect(mapStateToProps)(App);
