import React from 'react'
import './footer.scss'


export default class Footer extends React.Component {
  render() {
    return (
        <footer className="site-footer">
          <div className="container">
            <div className="row">
              <div className="copyright">
                <p>&copy; Copyright <a href='http://www.payupclimatepolluters.org/' title='Center for Climate Integrity'>Center for Climate Integrity</a>. All rights reserved  |  <a href='mailto:coststudy@climateintegrity.org' title='Contact Us'>Contact Us</a></p>
              </div>
            </div>
          </div>
        </footer>
    )
  }
}