import React from 'react'
import { connect } from 'react-redux'
import { findById } from '../../database'
import DataPointHighlight from '../../components/dataPointHighlight'
import DataPointFact from '../../components/dataPointFact'
import RelatedData from '../../components/relatedData'
import './costs-display.scss'
import {Link} from "react-router-dom";
import utils from '../../utils'

class CostsDisplay extends React.Component{
  render() {
    let location = findById(this.props.id)
    let state = location.type === 'state' ? null : findById(location.state)

    let cost = utils.formatNumber(location.cost)

    // Prepare related component
    let related
    if(location.type === 'state'){
      let image
      if(location.abbreviation !== 'DC'){
        image = (
          <img alt={location.name} src={require('../../images/maps/' + location.abbreviation + '_map@2x.png')} />
        )
      }

      related = (
        <div>
          <div className="related-data-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-6 related-data-content">
                  <RelatedData state={location} type='county' expandable={ true } includeHeading={ true } />
                </div>
                <div className="col-md-6 related-data-image" id={ 'map-svg-' + location.abbreviation }>
                  { image }
                </div>
              </div>
            </div>
          </div>
          <div className="seawall-costs-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-6 seawall-costs-content">
                  <RelatedData state={location} type='city' expandable={ true } />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    let costRankingLabel, seawallRankingLabel

    if(location.type === 'state'){
      costRankingLabel = 'most costly state'
      seawallRankingLabel = 'most miles of seawalls'
    }
    else{
      costRankingLabel = (
        <span>
          most costly in <Link to={'/costs/' + state.id} title={state.name}>{state.name}</Link>
        </span>
      )
      seawallRankingLabel = (
        <span>
          most miles of seawalls in <Link to={'/costs/' + state.id} title={state.name}>{state.name}</Link>
        </span>
      )
    }

    let seawall = parseFloat(location.seawall) || "< 0.5"

    let secondButton
    if(location.type === 'state'){
      secondButton = <div className=' animated fadeIn delay-3s'>
        <Link to={ '/how-will-you-pay/' + location.id } title='How on earth will you pay for this?' className='button centered'>Go deeper</Link>
        <div className='state-fact-sheet'>
          <a className='centered' href={ process.env.PUBLIC_URL + '/files/state/' + location.abbreviation + '.pdf' } title='State Fact Sheet'>State Fact Sheet</a>
        </div>
      </div>
    }

    let seawallUnit = 'Miles'
    if(seawall === 1){
      seawallUnit = 'Mile'
    }

    return (
      <div>
        <h1 className="page-title">{ location.name } in 2040</h1>
        <div className="costs">
          <div className="container">
            <div className="row">
              <div className="cost animated fadeIn delay-1s">
                <DataPointHighlight prefix='$' location={location} value={cost.value} unit={cost.unit} label='for seawalls'>
                  <DataPointFact value={utils.ordinalSuffix(location.costRanking)} label={costRankingLabel} />
                </DataPointHighlight>
              </div>
              <div className="cost animated fadeIn delay-2s">
                <DataPointHighlight location={location} value={utils.numberWithCommas(seawall)} unit={seawallUnit} label='of seawalls'>
                  <DataPointFact value={utils.ordinalSuffix(location.seawallRanking)} label={seawallRankingLabel} />
                </DataPointHighlight>
              </div>
            </div>
          </div>
        </div>
        <div className=' animated fadeIn delay-3s'>
          <Link to={ '/how-will-you-pay/' + location.id } title='How on earth will you pay for this?' className='button centered'>Go deeper</Link>
        </div>

        { related }

        { secondButton }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id
  }
}

export default connect(mapStateToProps)(CostsDisplay);
