import React from 'react'
import { findById } from '../../database'
import { Link } from 'react-router-dom'
import utils from '../../utils'

import './rankingsTable.scss'

export default class RankingsTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  renderStateCell( location ) {
    if(location.type === 'city'){
      let state = findById(location.state)
      return <td>{ state.name }</td>
    }

    return null
  }

  toggleExpand() {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    let expandedClass = ''
    let seeAll
    if (this.state.expanded) {
      expandedClass = 'expanded'
      seeAll = <span className='expand-table cost-link' onClick={this.toggleExpand.bind(this)}><i className="fas fa-caret-up"></i>Collapse list</span>
    }
    else{
      seeAll = <span className='expand-table cost-link' onClick={this.toggleExpand.bind(this)}><i className="fas fa-caret-down"></i>See all</span>
    }

    return (
      <div className={'rankings-table ' + expandedClass}>
        <a name={this.props.anchorName} href={'#' + this.props.anchorName}>
          <h2>{this.props.title}</h2>
        </a>
        <a href={ this.props.download } title='Download Rankings'>Download Rankings</a>
        <table>
          <tbody>
            {this.props.index.map((id, i) => {
              let location = findById(id)
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{location.name}</td>
                  { this.renderStateCell(location) }
                  <td>${utils.numberWithCommas(Math.round(location.cost / 1000) * 1000)}</td>
                  <td><Link to={'/costs/' + id} title={location.name} className="cost-link">See Cost</Link></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        { seeAll }
      </div>
    )
  }
}