import React from 'react'
import './dataPointHightlight.scss'

export default (props) => (
  <div className='data-point-highlight'>
    <div className="data-point">
      <div className='value'>{ props.prefix }{ props.value }</div>
      <div className='unit'>{ props.unit }</div>
      <div className='label'>{ props.label }</div>
    </div>
    {props.children}
  </div>
)