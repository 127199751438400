import React from 'react'
import Search from '../../components/search'
import Ticker from '../../components/ticker';

import './home.scss'

export default props => (
  <div className="home">
    <div className="container">
      <div className="row">
        <div className="home-content">
          <h2 className="home-title">$400 Billion for Seawalls.</h2>
          <h3 className="home-subtitle">Big Oil wants you to pay.</h3>
          <div className="intro-text">
            <p>Find out what it will cost to keep rising seas from flooding your hometown &mdash; before the bill arrives.</p>
          </div>
          <div className="search-wrapper">
            <Search />
          </div>
          <div className="intro-text">
            <p>More than 130 counties face billion-dollar price tags to defend against chronic flooding from climate-driven sea-level rise by 2040. As it stands, taxpayers and property owners will pay for all of it. <br/>Polluters will pay for none.</p>
          </div>
        </div>
      </div>
    </div>
    <Ticker />
  </div>
)