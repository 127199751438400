import React from 'react'
import { connect } from 'react-redux'
import { findById } from '../../database'
import './how-will-you-pay-costs.scss'

import DataPointHighlight from '../../components/dataPointHighlight'
import DataPointFact from '../../components/dataPointFact'

import { Link } from 'react-router-dom'

import utils from '../../utils'

class HowWillYouPayCosts extends React.Component{
  render() {
    let location = findById(this.props.id)
    let state = location.type === 'state' ? null : findById(location.state)

    let cost = utils.formatNumber(location.cost)

    let costRankingLabel
    if(location.type === 'state'){
      costRankingLabel = 'most costly state'
    }
    else{
      costRankingLabel = (
        <span>
          most costly in <Link to={'/costs/' + state.id} title={state.name}>{state.name}</Link>
        </span>
      )
    }

    let bigStat = (
      <DataPointHighlight prefix='$' location={location} value={cost.value} unit={cost.unit} label='in costs'>
        <DataPointFact value={utils.ordinalSuffix(location.costRanking)} label={costRankingLabel} />
      </DataPointHighlight>
    )

    let smallStat, budget, perCapitaCost
    switch(location.type){
      case 'state':
        budget = utils.formatNumber(location.budget)

        smallStat = (
          <DataPointHighlight prefix='$' location={location} value={budget.value} unit={budget.unit} label={ location.budgetYear + ' state budget' } />
        )
        break;
      case 'city':
        // On a city so show the cost per capita
        perCapitaCost = utils.numberWithCommas(location.perCapitaCost)

        smallStat = (
          <DataPointHighlight prefix='$' location={location} value={perCapitaCost} unit='cost per capita' />
        )
        break;
      case 'county':
        // On a county show the budget and per capita cost
        perCapitaCost = utils.numberWithCommas(location.perCapitaCost)
        budget = utils.formatNumber(location.budget)

        smallStat = (
          <div className='two-small-stats'>
            <DataPointHighlight prefix='$' location={location} value={budget.value} unit={budget.unit} label={ location.budgetYear + ' county budget' } />
            <DataPointHighlight prefix='$' location={location} value={perCapitaCost} unit='cost per capita' />
          </div>
        )
        break;
      default:
    }

    return (
      <div>
        <h1 className="page-title">Who is going to pay?</h1>
        <div className='container'>
          <div className='row'>
            <div className='big-stat animated fadeIn delay-1s'>
              { bigStat }
            </div>
            <div className='small-stat animated fadeIn delay-2s'>
              {smallStat}
              <p>At present, taxpayers and property owners are on the hook for 100% of climate adaptation costs. Unless something changes, communities will be forced to cut existing public services or raise taxes as these costs continue to rise.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12 animated fadeIn delay-3s'>
              <Link to={ '/how-will-you-pay-options/' + location.id } title="Let's Sea" className='button centered'>Let's Sea</Link>
            </div>

          </div>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id
  }
}

export default connect(mapStateToProps)(HowWillYouPayCosts);