import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.svg'

import './header.scss'

export default class Header extends React.Component {

  toggleNav(e){
   var mobileNavElement = document.getElementById('navbarSupportedContent');
   var togglerButton = document.getElementsByClassName('navbar-toggler')[0];
   mobileNavElement.classList.toggle('show');
   if(togglerButton.classList.contains('collapsed')) {
     togglerButton.classList.remove('collapsed');
  }else {
    togglerButton.classList.add('collapsed');
  }
  }


  render() {
    return (
      <header className="site-header">
        <div className="container-fluid">
          <nav className="row navbar navbar-expand-md">
            <div className="site-branding">
              <h1 className="navbar-brand">
                <div className='logo-link'>

                  <span className="site-name">Pay Up Climate Polluters</span>
                  <a href="http://payupclimatepolluters.org/"><img src={logo} alt="Pay Up Climate Polluters" className="site-logo"/></a>
                  <Link to='/' title='Home'>
                    <span className="site-slogan">Climate Costs in 2040</span>
                  </Link>

                </div>

              </h1>
            </div>
            <button onClick={this.toggleNav} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon navbar-open fas fa-bars"></span>
              <span className="navbar-toggler-icon navbar-close fas fa-times"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to='/about-the-study' title='About the Study' className="nav-link">About the Study</Link>
                </li>
                <li className="nav-item">
                  <Link to='/rankings' title='Rankings' className="nav-link">Rankings</Link>
                </li>
                <li className='nav-item'>
                  <Link to='/state-details' title='State Details' className='nav-link'>State Details</Link>
                </li>
                <li className="nav-item">
                  <Link to='/data-sets' title='Data Sets' className="nav-link">Data Sets</Link>
                </li>
                <li className="nav-item">
                  <Link to='/download-the-report' title='Download the Report' className="nav-link nav-button">Download the Report</Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}