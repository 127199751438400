export default {
  numberWithCommas: (num) => {
    num = typeof num === 'string' ? num : num.toString()
    let parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  },
  nameToId(name){
    return name.toLowerCase().replace(new RegExp(' ', 'g'), '-')
  },
  ordinalSuffix(number){
    let j = number % 10, k = number % 100;
    if (j === 1 && k !== 11) {
      return "#" + number;
    }
    if (j === 2 && k !== 12) {
      return number + "nd";
    }
    if (j === 3 && k !== 13) {
      return number + "rd";
    }
    return number + "th";
  },
  formatNumber(number){
    let output = {}

    if(number >= 1000000000){
      output.unit = 'Billion'
      output.value = this.numberWithCommas(Math.round(number / 100000000) / 10)
    }
    else if(number >= 1000000){
      output.unit = 'Million'
      output.value = this.numberWithCommas(Math.round(number / 100000) / 10)
    }
    else{
      output.unit = ''
      output.value = this.numberWithCommas(number)
    }

    return output
  }
}