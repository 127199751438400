import React from 'react'

export default () => (
  <div>
    <h1 className="page-title">Data Sets</h1>
    <div className='container'>

      <div className="text-content">

        <p>In the zip file below, you will find six Excel spreadsheets with cost and seawall length data at the congressional district, county, and city levels. Our website focuses only on results of the <strong>RCP 4.5, 2040, 50th percentile, 1-year storm surge</strong> model run. However, this study produced planning-level cost estimates for different years (2040, 2060, 2100) under two different future emissions scenarios (RCP 2.6 and RCP 4.5), with and without a 1-year storm surge. We include the 5th, 50th, and 95th percentiles in these data sets. Please see our methodology for more information.</p>

        <p><a href={ process.env.PUBLIC_URL + '/files/Climate_Costs_2040_Data.zip' } title='Download the Data'>Download the Data</a></p>

        <p>These are planning-level estimates only and should not take the place of a detailed engineering analysis.</p>

        <p>Contact us for more info at <a href="mailto:Coststudy@climateintegrity.org">Coststudy@climateintegrity.org</a>.</p>
        </div>

    </div>
  </div>
)