import alasql from 'alasql'

import index from './index.json'
import data from './data.json'

export default async (query) => {
  let docs = await alasql.promise(query, [index]);

  // Return array of full data objects
  return docs.map(doc => data[doc.id])
}

export function findById(id) {
  return data[id]
}

export async function findByName(name) {
  let docs = await alasql.promise('SELECT * FROM ? WHERE name = "' + name + '"', [index])
  if(docs.length > 0){
    return data[docs[0].id]
  }

  return null
}