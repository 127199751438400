import React from 'react'
import { connect } from 'react-redux'

import { findById } from '../../database'
import './make-big-oil-pay.scss'

import {Link} from "react-router-dom";

class MakeBigOilPay extends React.Component{
  render(){
    let location = findById(this.props.id)

    return (
      <div className='make-big-oil-pay'>
        <h1 className="page-title">Make Big Oil Pay Their Fair Share.</h1>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='intro-text'>
                <p className='animated fadeIn delay-1s'>Big oil and gas companies like Exxon knew since the 1970s that burning fossil fuels would warm the planet with devastating consequences. Instead of working to curb the risk, they spent millions to create and promote climate denial and obstruct action when it mattered most. Now they want taxpayers to foot the entire bill for all the damages.</p>
                <p className='animated fadeIn delay-1s'>Communities cannot afford the massive costs of climate adaptation on their own.</p>
                <p className='animated fadeIn delay-1s'>These costs weren’t always inevitable. Now that they are, polluters should pay their fair share.</p>
                <div className='animated fadeInUp delay-2s'>
                  <h2 className='share-heading'>Share with your community</h2>
                  <div className='share-arrow'>
                    <i className='fas fa-arrow-down'></i>
                  </div>
                </div>
              </div>
              <div className='shareImage animated fadeInUp delay-3s'>
                <img src={ '/images/location/' + location.id + '.png' } alt={location.name} />
              </div>
              <div className='shareIcons'>
                <a href={'https://www.twitter.com/share?url=' + encodeURIComponent( window.location.origin + '/costs/' + location.id ) } target='_blank'><i className='fab fa-twitter'></i></a>
                <a href={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent( window.location.origin + '/costs/' + location.id ) } target='_blank'><i className='fab fa-facebook-f'></i></a>
                {/* <i className='fas fa-envelope'></i> */}
              </div>
              <div className='buttons'>
                <Link to='/download-the-report' title='Download the Report' className="button">Download the Report</Link>
                <a className='button' href='/' title='Try another search'>Try another search</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.match.params.id
  }
}

export default connect(mapStateToProps)(MakeBigOilPay);