import React from 'react'

class DownloadTheReport extends React.Component {
  render() {
    return (
      <div>
        <h1 className="page-title">Download the Report</h1>
        <div className='download-the-report'>
          <div className='container'>
            <div className="text-content">
              <p>Adapting to climate change in the U.S. will cost trillions of dollars. But to date, there has been
                little analysis of what the costs will be to individual states, counties, congressional districts, and
                cities across the country. This study provides conservative cost estimates for one small but essential
                piece of climate adaptation: building seawalls and other barriers to protect the coastal contiguous US
                from near-term sea-level rise. While these numbers represent just a slice of the total cost to protect
                communities from a much wider range of climate impacts, we present these planning-level estimates to
                identify high-risk communities with the greatest costs, to inform decisions over the next 5-10 years,
                and to provide a better idea of the scale of climate adaptation costs taxpayers are currently expected
                to cover on their own. See the full report below.</p>
              <p><a href={process.env.PUBLIC_URL + '/files/ClimateCosts2040_Report.pdf'}
                    title='Download the Full Report'>Download the Full Report</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DownloadTheReport