import React from 'react'
import rankings from '../../rankings.json'
import { findById } from '../../database'
import utils from '../../utils'

import '../../components/rankingsTable/rankingsTable.scss'

class StateDetails extends React.Component {
  render() {
    return (
      <div>
        <h1 className="page-title">State Details</h1>
        <div className='container'>
          {/* <p>As sea-level rise will hit some coastal communities harder than others and price of construction varies from place to place, communities will face vastly different costs to protect against rising seas. See if yours made it to the top of the list.</p> */}

          <div className='rankings-table expanded'>
            <h2>State Fact Sheets</h2>
            <table>
              <tbody>
                {rankings.states.map((id, i) => {
                  let location = findById(id)
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{location.name}</td>
                      <td>${utils.numberWithCommas(Math.round(location.cost / 1000) * 1000)}</td>
                      <td><a className='cost-link' href={ process.env.PUBLIC_URL + '/files/state/' + location.abbreviation + '.pdf' } title='State Fact Sheet'>State Fact Sheet</a></td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default StateDetails