import { withRouter } from 'react-router-dom'
import React from 'react'

class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && this.props.history.action !== "POP") {
      let scrollDistancePerInterval = 10
      let scrollInt = setInterval( _ => {
        let nextTarget = window.scrollY - scrollDistancePerInterval
        window.scrollTo( 0, nextTarget > 0 ? nextTarget : 0 )
        if(nextTarget <= 0){
          clearInterval(scrollInt)
        }
      }, 30)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop)