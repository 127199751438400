import React from 'react'

export default () => (
  <article>
    <h1 className="page-title">About the Study</h1>

    <div className="text-content">


  

      <h3>ABOUT THE STUDY</h3>

      <ul>
        <li>
          <h4>Why did we conduct this study?</h4>
          <p>Communities across the country are already paying billions to protect themselves, their homes, businesses, infrastructure, and livelihoods from climate impacts. Yet most states and municipalities do not track the cost of climate mitigation and adaptation projects, let alone identify alternative sources of revenue to help pay for them. Meanwhile, fossil fuel companies continue to profit from the very products causing the climate crisis. The study provides a baseline estimate for one set of climate adaptation and resilience expenses to inform a more robust debate on who should pay these costs: taxpayers or polluters.</p>
        </li>
        <li>
          <h4>Why is this information useful?</h4>
          <p>While the cost of raising seawalls and other protective barriers is only a small portion of the massive financial burden communities face to protect themselves and repair the damage from climate impacts, these figures bring us one step closer to understanding the toll climate change will take on taxpayers without other sources of revenue. We hope this report  provides a useful estimate of how much climate change will cost your community. By extension, these numbers reinforce the need for transparency in accounting for these costs in budgetary planning and decision-making. The conclusions of the study can provide the basis for an informed discussion about alternative courses of action and additional sources of revenue beyond raising taxes for necessary adaptation projects.</p>
        </li>
        <li>
          <h4>Will there be other climate adaptation costs?</h4>
          <p>Yes &mdash; many. While our study tackles the near-term costs of adapting to and protecting from sea-level rise, climate impacts ranging from extreme weather events to public health threats, heat waves, drought and increased heavy precipitation events—to name just a few— will require far more revenue than accounted for here. Our study represents a conservative estimate of just one set of costs to adapt to sea-level rise. In addition to these basic defenses, communities will need to protect drinking water resources, replace water mains and upgrade sewer treatment facilities, raise and repair roads, control larger and more frequent wildfires, renourish beaches as rising seas lead to increased erosion, provide aid to growing numbers of climate refugees (especially low-income families of color displaced by climate gentrification), retrofit stormwater drainage infrastructure, build cooling centers and air-conditioned public housing, adjust to longer, hotter droughts, recover from more severe storms, and respond to the spread of vector-borne diseases like Lyme, West Nile virus and Zika.</p>
        </li>
        <li>
          <h4>These numbers are huge and I’m concerned. What can I do?</h4>
          <p>The first step to tackling any issue is to make sure as many people as possible understand the issue, particularly elected officials and decision-makers. Host an event in your hometown to discuss the report, attend a town hall, share the numbers on social media and send them to family members. Make sure your local leaders are aware of these numbers—go through your local advocacy group or reach out to representatives directly. Ask them to start transparently tracking the various other costs associated with adapting to climate change, and to make cost accounting part of budgetary planning and decision-making. Make “Who pays?” and “Who should pay?” for adaptation costs central questions in any climate-focused conversation.</p>
        </li>
        <li>
          <h4>I don’t live on the coast: Why should I care?</h4>
          <p>From droughts to storms and rapidly rising temperatures, communities across the U.S. will experience a range of climate impacts over the next several decades, whether or not they are on the coast. These communities will still have to pay to adapt, rebuild, and save lives from the various threats posed by climate change.</p>
        </li>
      </ul>

      <h3>ABOUT THE DATA</h3>

      <ul>
        <li>
          <h4>How was the study conducted? </h4>
          <p>We partnered with Resilient Analytics, an engineering firm specializing in climate adaptation, and mapping and GIS specialists at the University of Colorado, to generate the estimated costs of constructing seawalls to protect infrastructure in the contiguous United States from sea-level rise. By pairing a sophisticated sea-level rise model (Kopp et al., 2014) with 1-year storm surge estimates (Tebaldi et al., 2012; Buchanan et al., 2016), as well as the NOAA Medium Resolution Shoreline dataset, we have produced planning-level cost estimates for different years (2040, 2060, 2100) under two different future emissions scenarios (RCP 2.6 and RCP 4.5), with and without a 1-year storm surge. If you have more questions about the science and methodology behind this study, please see our methodology section or email us at <a href="mailto:Coststudy@climateintegrity.org">Coststudy@climateintegrity.org</a>. </p>
        </li>
        <li>
          <h4>What does RCP mean? </h4>
          <p>Representative Concentration Pathways (RCPs) are scenarios that describe four alternative trajectories of CO2 emissions and the resulting atmospheric CO2 concentrations between the years 2000-2100. These scenarios encompass a range of possible climate policy outcomes by the twenty-first century based on different assumptions about population, economic growth, energy consumption and source, and land use. This limited set of scenarios ensures that researchers around the world, especially climate modelers, can conduct research that is comparable. The scenarios range from RCP2.6, the most aggressive in reducing carbon emissions, to RCP8.5, considered a “business as usual” scenario in which no effort is taken to reduce emissions. </p>
        </li>
        <li>
          <h4>Why is RCP4.5 featured here?</h4>
          <p>In this study, we focus on RCP4.5, a “stabilization scenario” in which emissions peak around 2050 at about twice the level of emissions in the year 2000, then decline rapidly for 30 years, finally stabilizing at roughly half of the year 2000 levels. We deliberately did not analyze more severe or worst case scenarios in order to focus attention on near term, unavoidable costs that all coastal communities will face. In fact, current carbon emissions are ahead of  the RCP4.5 projection, making it quite likely that RCP4.5 underestimates future climate change adaptation costs.</p>
        </li>
        <li>
          <h4>What does 1-year storm surge mean?</h4>
          <p>A "1-year storm surge" is the level to which the coastal water rises in any given year during a typical storm according to historical sea-level data. It is an extremely common storm event, as opposed to a “100-year storm surge,” which represents a severe event that statistically occurs once every 100 years.</p>
        </li>
        <li>
          <h4>How did we choose the locations represented in this study? </h4>
          <p>Any part of the contiguous US that is subject to tidal movements is included in this study. That shoreline is defined by the National Oceanic and Atmospheric Administration (NOAA) Medium-Resolution Shoreline dataset. </p>
        </li>
        <li>
          <h4>How does this study compare to other estimates?</h4>
          <p>This is the first-ever estimate of the costs of coastal defenses for the tidal shoreline of the contiguous United States. Of course, individual cities, counties and states have conducted their own assessments of the costs of climate resilience using various assumptions about future sea-level rise and temperature increases. Climate resiliency experts recommend that coastal communities prepare for much worse sea-level rise scenarios and more destructive and frequent extreme weather events, such as hurricanes and heavy precipitation events, than are assessed in this analysis. Most local governments that have begun the process of protecting their shorelines have taken this to heart and are preparing for longer-term sea-level rise and more frequent extreme weather. We chose a near-term moderate scenario with the goal of producing cost estimates that represent a minimum down payment on short-term sustainability for coastal communities and developed property. As a result, most existing cost estimates tend to be higher than our conservative values.</p>
        </li>
        <li>
          <h4>What about Alaska, Hawaii, Puerto Rico, Guam, American Samoa, and the US Virgin Islands?  </h4>
          <p>Every one of these places is experiencing the devastating effects of sea-level rise. They were not included in this analysis because one of the most important data sets underlying this study, the NOAA Medium-Resolution Shoreline, includes only the contiguous US.</p>
        </li>
        <li>
          <h4>Is this the worst case scenario? </h4>
          <p>Far from it. This study only measures the cost of building seawalls to protect infrastructure (such as roads, buildings and homes) from moderate (not worst case) sea-level rise induced flooding over the next 20 years. Specifically, the numbers you see on this website reflect the costs of protecting property and infrastructure from a 4.5 RCP, 1-year storm surge sea-level rise scenario by the year 2040. However, you can find data for the years 2060 and 2100—as well as other RCP and storm surge scenarios―in our full dataset. </p>
        </li>
      </ul>

      <h3>ABOUT PROTECTION FROM SEA-LEVEL RISE</h3>

      <ul>
        <li>
          <h4>What is a seawall?</h4>
          <p>Seawalls are protective barriers commonly used to reduce the impact of sea-level rise on coastal communities. This study estimates cost based on two different types of seawall: coastal and inland. Coastal seawalls have been used to protect wave-impacted coastlines and to stop or reduce the impacts of flooding. In this study, coastal seawalls are defined as retaining walls made of fieldstone built on the shoreline. This design is utilized wherever the coast is directly exposed to open water. Inland seawalls, often referred to as bulkheads, are used to protect property against rising inland water levels and indirect wave action. </p>
        </li>
        <li>
          <h4>Why seawalls as opposed to any other climate cost?</h4>
          <p>Seawalls are an excellent indicator of the costs that coastal communities will face in adapting to climate change; these costs are certain and already being incurred in many communities. Further, the need for seawalls is driven by warming-induced increases in sea-level rise, which is very well understood and strongly linked to fossil fuel emissions and other greenhouse gases. At the local level, the factors that determine how much sea-level will rise in any given location are also well studied, meaning that it’s reasonable to create a single method to produce planning-level coastal-protection cost estimates that can be applied to the entire contiguous US. </p>
        </li>
        <li>
          <h4>Are there examples of communities that have already started to build seawalls?</h4>
          <p>Yes. Miami Beach and portions of Broward County in South Florida; San Francisco, California; Charleston, South Carolina; Atlantic City, New Jersey; and more are already experiencing chronic flooding from sea-level rise and have begun raising seawalls and other barriers in response. Other cities have started scoping out the costs: the tiny city of DelRay Beach, FL determined that it would need to pay <a href="https://www.theinvadingsea.com/2019/02/13/378-million-price-tag-to-prevent-flooding-leaves-delray-beach-reeling/">over $378 million</a> to elevate roads and seawalls and improve pipes to stymie damage from encroaching seas. </p>
        </li>
        <li>
          <h4>Does the study focus only on seawalls that cities and counties have to build, or does this account for private property?</h4>
          <p>The primary focus of this study is to ensure roads, rails, and public infrastructure are protected from the predicted impacts of sea-level rise. Although the study does not consider private residences directly, the location of most residential areas can be determined through the location of roads that are used to access residential areas. By considering all areas that contain a road (both paved and unpaved), the majority of residential areas are also protected. Areas that do not have any public infrastructure, such as national parks or protected wildlife areas, were not included in the study as pieces of infrastructure and were therefore not considered for protection.</p>
        </li>
        <li>
          <h4>I’ve heard that seawalls won’t work in every location. How do you account for that in this analysis?</h4>
          <p>Seawalls are not a magic bullet. Some locations are unsuitable to build seawalls because the underlying substrate is porous or due to a variety of direct and indirect environmental impacts that may be caused by seawall construction itself. In this planning-level assessment, we’re estimating the cost of building coastal and inland seawalls in every location that will need some form of protection against rising seas. Ultimately, decision-makers will need to come up with the method and location of protection that makes the most sense for their situation.</p>
        </li>
      </ul>

      <h3>ABOUT CLIMATE LIABILITY</h3>

      <ul>
        <li>
          <h4>What is climate liability?</h4>
          <p>Climate liability reflects the legal principle that climate polluters, like anyone else, should be responsible for the damages their actions or products cause. Climate liability is not a new or novel idea— it simply applies the time tested legal principles of liability to the pressing issue of climate change. Academic and nonprofit reports show conclusively that big oil and gas companies knew burning fossil fuels <a href="http://www.payupclimatepolluters.org/smoking-guns/">would lead to climate change as early as 1968</a>. Instead of warning the public and leading the transition to clean energy, they ran a massive multi-million dollar disinformation campaign that deliberately sowed doubt about scientific realities and downplayed risks. Now these companies are arguing that they have no financial obligation &mdash; none &mdash; to pay for any of the damage caused by climate change or defenses needed to protect against it.</p>
        </li>
      </ul>

    </div>

    </article>


)