import React from 'react'
import Autosuggest from 'react-autosuggest'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, Redirect } from 'react-router-dom'
import ReactDOM from 'react-dom'
import db, { findByName, findById } from '../../database'

import './search.scss'

const mapStateToProps = _ => ({

})

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch)

const getSuggestions = value => {
  let inputValue = escape(value.trim().toLowerCase())

  // Convert "%20" back to a space to properly search names
  inputValue = inputValue.replace(/%20/g, ' ')

  return inputValue.length < 2 ? null : db('SELECT * FROM ? WHERE name LIKE "%' + inputValue + '%"');
}

const getSuggestionValue = suggestion => suggestion.name

const renderSuggestion = suggestion => {
  let name = suggestion.name

  // If not a state or district, append the state abbreviation
  if (suggestion.type === 'city' || suggestion.type === 'county') {
    name += ', ' + findById(suggestion.state).abbreviation
  }

  let type = suggestion.type === 'district' ? 'congressional district' : suggestion.type

  return (
    <div>
      <Link to={"/costs/" + suggestion.id}>{name} <span className='location-type'>- {type}</span></Link>
    </div>
  )
}

class Search extends React.Component {
  constructor() {
    super()

    this.autoscrollComplete = false

    this.state = {
      value: '',
      highlightedLocation: null,
      selectedLocation: null,
      suggestions: []
    }
  }

  onChange = (event, { newValue }) => {
    if (newValue.length > 2) {
      findByName(newValue).then(location => {
        this.setState({
          highlightedLocation: location,
          value: newValue
        })
      })
    }
    else {
      this.setState({
        highlightedLocation: null,
        value: newValue
      })
    }

    /**
     * If there are enough characters in the input to search then start scrolling down
     */
    if (newValue.length > 1) {
      if (!this.instance) {
        this.instance = ReactDOM.findDOMNode(this)
      }
      let target = this.instance.getBoundingClientRect().top
      if (window.scrollY < target) {
        // Start interval to scroll to better location
        let scrollDistancePerInterval = 40
        let prevY = 0
        let scrollInt = setInterval(_ => {
          let nextTarget = window.scrollY + scrollDistancePerInterval
          window.scrollTo(0, nextTarget <= target ? nextTarget : target)
          if (nextTarget >= target || prevY === window.scrollY) {
            clearInterval(scrollInt)
            prevY = 0
          }
          prevY = window.scrollY
        }, 30)
      }
    }
  }

  onSuggestionsFetchRequested = ({ value }) => {
    let result = getSuggestions(value)

    if (!result) {
      this.setState({
        suggestions: []
      })
    }
    else {
      result.then(docs => {
        this.setState({
          suggestions: docs
        })
      })
    }
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  onSuggestionSelected = (value) => {
    // If a location is highlighted, set it as the selected location
    if (this.state.highlightedLocation) {
      this.setState({
        selectedLocation: this.state.highlightedLocation
      })
    }
  }

  render() {
    if (this.state.selectedLocation) {
      return <Redirect to={'/costs/' + this.state.selectedLocation.id} />
    }

    const inputProps = {
      placeholder: 'Enter location name',
      value: this.state.value,
      onChange: this.onChange,
      id: 'inline-search'
    }

    return (
      <div className="search">
        <label htmlFor='inline-search'>Search by State, City, Congressional District, or County</label>
        <Autosuggest
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionSelected={this.onSuggestionSelected}
          inputProps={inputProps}
        />
        <i className='fas fa-search'></i>
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)