import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'
import ScrollTop from './components/scrollTop'

import TagManager from 'react-gtm-module'

import '@fortawesome/fontawesome-free/css/all.css'
import './bootstrap-theme.scss'

const target = document.querySelector('#root')

const tagManagerArgs = {
  gtmId: 'GTM-KSF2S8B'
}

TagManager.initialize(tagManagerArgs)

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollTop>
        <div>
          <App />
        </div>
      </ScrollTop>
    </ConnectedRouter>
  </Provider>,
  target
)