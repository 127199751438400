import React from 'react'
import { findById } from '../../database'
import { Link } from 'react-router-dom'

import './ticker.scss'

class Ticker extends React.Component{
  constructor(props){
    super(props)

    this.state = {
      activeIndex: 3
    }

    this.locations = [
      'new-jersey',
      'florida',
      'south-carolina',
      'washington',
      'california',
      'north-carolina',
      'virginia',
      'texas',
      'maryland'
    ]
  }

  incrementIndex() {
    this.setState({
      activeIndex: this.state.activeIndex + 1
    })
  }

  decrementIndex() {
    this.setState({
      activeIndex: this.state.activeIndex - 1
    })
  }

  render(){
    let items = []

    this.locations.map( (id, index) => {
      let location = findById(id)

      let style
      if(index === 0){
        if(window.innerWidth >= 768){
          style = {marginLeft: 'calc( (3 - ' + this.state.activeIndex + ') * 33.33333% + 10px)'}
        }
        else{
          style = {marginLeft: 'calc( (3 - ' + this.state.activeIndex + ') * 100% + 10px)'}
        }
      }

      items.push((
        <div className='ticker-item' key={id} style={ style }>
          <strong> { location.name }</strong> &ndash; { location.seawall } miles of seawalls<Link to={ '/costs/' + id } title='See Cost' className='cost-link'>See Cost</Link>
        </div>
      ))

      return id
    })

    let prevButton
    if(this.state.activeIndex > 2){
      prevButton = <button className='previous fas fa-chevron-left' onClick={ this.decrementIndex.bind(this) }><span className="icon"></span></button>
    }
    let nextButton
    if(this.state.activeIndex <= this.locations.length){
      nextButton = <button className='next fas fa-chevron-right' onClick={ this.incrementIndex.bind(this) }><span className="icon"></span></button>
    }

    return (
      <div className='ticker'>
        <div className="container-fluid">
          <div className="row">
            { items }
            {/* <div className="ticker-item">In <strong>Annapolis</strong>, the tide has come in 540,000 times, but now it’s worrisome&hellip;</div>
            <div className="ticker-item active"><strong>Charleston South Carolina</strong> &ndash; 649 miles of seawall (#1 in SC) &ndash; <span className="cost-link">See Cost</span></div>
            <div className="ticker-item"><strong>FL 12th Congressional District</strong> &ndash; 4,34 miles of seawall (#1 in FL, #3 &ndash; <span className="cost-link">See Cost</span></div>
            <div className="ticker-item">In <strong>Annapolis</strong>, the tide has come in 540,000 times, but now it’s worrisome&hellip;</div>
            <div className="ticker-item"><strong>FL 12th Congressional District</strong> &ndash; 4,34 miles of seawall (#1 in FL, #3 &ndash; <span className="cost-link">See Cost</span></div> */}
            <div className="ticker-nav">
              { prevButton }
              { nextButton }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Ticker