import React from 'react'
import './relatedData.scss'
import { findById } from '../../database'
import utils from '../../utils';
import { Link } from 'react-router-dom'

export default class RelatedData extends React.Component{
  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  toggleFullDisplay(){
    if(!this.props.expandable){
      return
    }

    this.setState({
      expanded: !this.state.expanded
    })
  }

  render() {
    let title = 'Most costly ' + this.props.state.name

    switch (this.props.type) {
      case 'city':
        title += ' cities'
        break
      case 'county':
        title += ' counties'
        break
      default:
    }

    let seeAll
    if (this.props.expandable) {
      let seeAllText = 'See all'
      if(this.state.expanded){
        seeAllText = 'Hide additional ' + (this.props.type === 'city' ? 'cities' : 'counties')
      }
      seeAll = <span className='expand-table cost-link' onClick={this.toggleFullDisplay.bind(this)}>{ seeAllText }</span>
    }

    let expandedClass = ''
    if(this.state.expanded){
      expandedClass = ' expanded '
    }

    let heading
    if(this.props.includeHeading){
      heading = <div className="label">Related Data</div>
    }

    return (
      <div className={ "relatedData" + expandedClass }>
        { heading }
        <table>
          <thead>
            <tr>
              <th colSpan="3" scope="col">{title}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.state.mostCostly[this.props.type].map((id, index) => {
              let location = findById(id)
              let cost = utils.formatNumber(location.cost)

              return (
                <tr key={id}>
                  <th scope='row'>#{index + 1}</th>
                  <td><strong>{location.name}</strong> (${cost.value} {cost.unit} for seawalls)</td>
                  <td><Link className="cost-link" to={'/costs/' + id} >See Cost</Link></td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {seeAll}
      </div>
    )
  }
}